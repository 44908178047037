import React, { ChangeEvent, useEffect, useState } from 'react';

import nexo from '../../nexoClient';

import { Page, Layout, FormField } from '@nimbus-ds/patterns';
import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Input,
  Select,
  Spinner,
  Text,
  Title,
  Toggle,
  useToast,
} from '@nimbus-ds/components';
import { navigateHeader } from '@tiendanube/nexo/helpers';
import { useTranslation } from 'react-i18next';
import apiInstance from '../../utils/apiUtils';
import {
  IProductGridView,
  MethodOptions,
  ThemeOptions,
  ThumbSizesOptions,
  defaultProductGridView,
} from '../../lib/interfaces/productGridView.interfaces';
import imgMinimal from '../../lib/assets/minimal.png';
import imgMinimalNotThumb from '../../lib/assets/minimal_not_thumb.png';
import imgTable from '../../lib/assets/table.png';
import imgTableNotThumb from '../../lib/assets/table_not_thumb.png';
import imgShowcase from '../../lib/assets/showcase.png';
import { BillingButton } from '../../components';

const ProductGridViewPage: React.FC = () => {
  const { t } = useTranslation();

  const { addToast } = useToast();
  const [loading, setLoading] = useState<boolean>(true);
  const [formData, setFormData] = useState<IProductGridView | null>();

  const getData = async () => {
    setLoading(true);
    try {
      const result = await apiInstance.get(`/product-grid-view`);
      if (result.data !== null) {
        const resData = result.data;
        setFormData({ ...defaultProductGridView, ...resData });
      }
    } catch (error) {
      setFormData({ ...defaultProductGridView });
      addToast({
        id: 'error_get' + new Date().toISOString(),
        type: 'danger',
        text: `${t('ProductGridView.Toasts.get_data_error')}`,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    navigateHeader(nexo, { goTo: '/', text: `${t('general.back')}` });
    getData();
  }, []);

  const handleChangeForm = (
    event: ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >,
  ) => {
    const { name, value, type } = event.target;

    const helpConfig: any = { ...formData };
    helpConfig[name] =
      type === 'checkbox' ? (event.target as HTMLInputElement).checked : value;
    setFormData({ ...helpConfig });
    return false;
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const payload = { ...formData };
      delete payload._id;
      delete payload.createdAt;
      delete payload.updatedAt;
      delete payload.store_id;
      const result = await apiInstance.post(`/product-grid-view`, payload);

      if (result.status === 200 && result.data.status) {
        addToast({
          id: 'submit' + new Date().toISOString(),
          type: 'success',
          text: `${t('ProductGridView.Toasts.submit_success')}`,
        });
      } else {
        addToast({
          id: 'submit' + new Date().toISOString(),
          type: 'danger',
          text: `${t('ProductGridView.Toasts.submit_danger')}`,
        });
      }
      setLoading(false);
    } catch (error) {
      addToast({
        id: 'submit' + new Date().toISOString(),
        type: 'danger',
        text: `${t('ProductGridView.Toasts.submit_danger')}`,
      });
      setLoading(false);
    }
  };

  const ThemeImgPreview: React.FC = () => {
    let image: any = null;
    const theme = formData?.theme || ThemeOptions.MINIMAL;
    const withThumb =
      formData?.show_variant_color && formData?.show_variant_color_thumb
        ? true
        : false;
    if (theme === ThemeOptions.TABLE) {
      image = withThumb ? imgTable : imgTableNotThumb;
    }
    if (theme === ThemeOptions.MINIMAL) {
      image = withThumb ? imgMinimal : imgMinimalNotThumb;
    }
    if (theme === ThemeOptions.SHOWCASE) {
      image = imgShowcase;
    }
    if (image !== null) {
      return <img src={image} width="100%" height="auto" />;
    }
    return <></>;
  };

  const PositionMethodOptions: React.FC = () => {
    return (
      <>
        <Select.Option
          value={MethodOptions.BEFORE}
          label={t(
            'ProductGridView.Form.Fields.General.position_method_option_1',
          )}
        />
        <Select.Option
          value={MethodOptions.PREPEND}
          label={t(
            'ProductGridView.Form.Fields.General.position_method_option_2',
          )}
        />
        <Select.Option
          value={MethodOptions.IN}
          label={t(
            'ProductGridView.Form.Fields.General.position_method_option_3',
          )}
        />
        <Select.Option
          value={MethodOptions.APPEND}
          label={t(
            'ProductGridView.Form.Fields.General.position_method_option_4',
          )}
        />
        <Select.Option
          value={MethodOptions.AFTER}
          label={t(
            'ProductGridView.Form.Fields.General.position_method_option_5',
          )}
        />
      </>
    );
  };

  return (
    <>
      <Page>
        <Page.Header
          title={`${t(`ProductGridView.title`)}`}
          buttonStack={<BillingButton />}
        />
        <Page.Body>
          <Layout columns="2 - asymmetric">
            <Layout.Section>
              <Box display="grid" gap="6" boxSizing="border-box">
                {!formData || !formData.is_active ? (
                  <Alert
                    appearance="danger"
                    title={`${t(`ProductGridView.disabled_app_title`)}`}
                  >
                    {t(`ProductGridView.disabled_app_message`)}
                  </Alert>
                ) : (
                  <></>
                )}
                {!formData ? (
                  <></>
                ) : (
                  <>
                    <Card>
                      <Card.Header>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Title as="h4">{`${t(
                            'ProductGridView.Cards.Rules.title',
                          )}`}</Title>
                          <Toggle
                            name="is_active"
                            label={`${t(
                              'ProductGridView.Form.Fields.is_active.label',
                            )}`}
                            disabled={loading}
                            active={formData.is_active}
                            onChange={handleChangeForm}
                          />
                        </Box>
                      </Card.Header>
                      <Card.Body>
                        <Box display="grid" gap="6">
                          {/* <Checkbox
                            name="show_in_showcase"
                            disabled={loading}
                            checked={formData.show_in_showcase}
                            onChange={handleChangeForm}
                            label={`${t(
                              'ProductGridView.Form.Fields.show_in_showcase.label',
                            )}`}
                          /> */}
                          <Checkbox
                            name="hide_not_stock"
                            disabled={loading}
                            checked={formData.hide_not_stock}
                            onChange={handleChangeForm}
                            label={`${t(
                              'ProductGridView.Form.Fields.hide_not_stock.label',
                            )}`}
                          />
                          <Checkbox
                            name="reset_value_after_buy"
                            disabled={loading}
                            checked={formData.reset_value_after_buy}
                            onChange={handleChangeForm}
                            label={`${t(
                              'ProductGridView.Form.Fields.reset_value_after_buy.label',
                            )}`}
                          />
                          <FormField
                            label={`${t(
                              'ProductGridView.Form.Fields.initial_qty_value.label',
                            )}`}
                          >
                            <Input
                              type="number"
                              min={0}
                              disabled={loading}
                              id="initial_qty_value"
                              name="initial_qty_value"
                              onChange={handleChangeForm}
                              value={formData?.initial_qty_value}
                            />
                          </FormField>
                        </Box>
                      </Card.Body>
                    </Card>

                    <Card>
                      <Card.Header
                        title={`${t('ProductGridView.Cards.Show.title')}`}
                      />
                      <Card.Body>
                        <Box display="grid" gap="6">
                          <FormField
                            label={`${t(
                              'ProductGridView.Form.Fields.theme.label',
                            )}`}
                          >
                            <Select
                              disabled={loading}
                              id="theme"
                              name="theme"
                              onChange={handleChangeForm}
                              value={formData?.theme}
                            >
                              <Select.Option
                                value={ThemeOptions.MINIMAL}
                                label={t(
                                  'ProductGridView.Form.Fields.theme.option_1',
                                )}
                              />
                              <Select.Option
                                value={ThemeOptions.TABLE}
                                label={t(
                                  'ProductGridView.Form.Fields.theme.option_2',
                                )}
                              />
                              <Select.Option
                                value={ThemeOptions.SHOWCASE}
                                label={t(
                                  'ProductGridView.Form.Fields.theme.option_3',
                                )}
                              />
                            </Select>
                          </FormField>
                          {formData.theme === ThemeOptions.SHOWCASE ? (
                            <Alert appearance="warning">
                              {t(
                                'ProductGridView.Form.Fields.theme.alert_showcase',
                              )}
                            </Alert>
                          ) : (
                            <></>
                          )}
                          <Checkbox
                            name="active_scroll"
                            disabled={
                              loading ||
                              formData.theme === ThemeOptions.SHOWCASE
                            }
                            checked={
                              formData.active_scroll ||
                              formData.theme === ThemeOptions.SHOWCASE
                            }
                            onChange={handleChangeForm}
                            label={`${t(
                              'ProductGridView.Form.Fields.active_scroll.label',
                            )}`}
                          />
                          <Checkbox
                            name="show_variant_color"
                            disabled={
                              loading ||
                              formData.theme === ThemeOptions.SHOWCASE
                            }
                            checked={
                              formData.show_variant_color ||
                              formData.theme === ThemeOptions.SHOWCASE
                            }
                            onChange={handleChangeForm}
                            label={`${t(
                              'ProductGridView.Form.Fields.show_variant_color.label',
                            )}`}
                          />
                          {formData.show_variant_color ? (
                            <>
                              <Checkbox
                                name="show_variant_color_thumb"
                                disabled={
                                  loading ||
                                  formData.theme === ThemeOptions.SHOWCASE
                                }
                                checked={
                                  formData.show_variant_color_thumb ||
                                  formData.theme === ThemeOptions.SHOWCASE
                                }
                                onChange={handleChangeForm}
                                label={`${t(
                                  'ProductGridView.Form.Fields.show_variant_color_thumb.label',
                                )}`}
                              />
                              <FormField
                                label={`${t(
                                  'ProductGridView.Form.Fields.variant_color_size.label',
                                )}`}
                              >
                                <Select
                                  disabled={
                                    loading ||
                                    formData.theme === ThemeOptions.SHOWCASE
                                  }
                                  id="variant_color_size"
                                  name="variant_color_size"
                                  onChange={handleChangeForm}
                                  value={formData?.variant_color_size}
                                >
                                  <Select.Option
                                    value={ThumbSizesOptions.SM}
                                    label={t(
                                      'ProductGridView.Form.Fields.variant_color_size.option_1',
                                    )}
                                  />
                                  <Select.Option
                                    value={ThumbSizesOptions.MD}
                                    label={t(
                                      'ProductGridView.Form.Fields.variant_color_size.option_2',
                                    )}
                                  />
                                  <Select.Option
                                    value={ThumbSizesOptions.LG}
                                    label={t(
                                      'ProductGridView.Form.Fields.variant_color_size.option_3',
                                    )}
                                  />
                                </Select>
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ProductGridView.Form.Fields.show_variant_color.label',
                                )}`}
                              >
                                <Input
                                  disabled={
                                    loading ||
                                    (!formData.show_variant_color &&
                                      !formData.show_variant_color_thumb)
                                  }
                                  id="variant_color_name"
                                  name="variant_color_name"
                                  onChange={handleChangeForm}
                                  value={formData?.variant_color_name}
                                />
                              </FormField>
                            </>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Card.Body>
                    </Card>

                    <Card>
                      <Card.Header
                        title={`${t('ProductGridView.Cards.Messages.title')}`}
                      />
                      <Card.Body>
                        <Box display="grid" gap="6">
                          <FormField
                            label={`${t(
                              'ProductGridView.Form.Fields.message_cta_add_all.label',
                            )}`}
                          >
                            <Input
                              disabled={loading}
                              id="message_cta_add_all"
                              name="message_cta_add_all"
                              onChange={handleChangeForm}
                              value={formData?.message_cta_add_all}
                            />
                          </FormField>
                          <FormField
                            label={`${t(
                              'ProductGridView.Form.Fields.message_select_products.label',
                            )}`}
                          >
                            <Input
                              disabled={loading}
                              id="message_select_products"
                              name="message_select_products"
                              onChange={handleChangeForm}
                              value={formData?.message_select_products}
                            />
                          </FormField>
                          <FormField
                            label={`${t(
                              'ProductGridView.Form.Fields.message_loader.label',
                            )}`}
                          >
                            <Input
                              disabled={loading}
                              id="message_loader"
                              name="message_loader"
                              onChange={handleChangeForm}
                              value={formData?.message_loader}
                            />
                          </FormField>
                        </Box>
                      </Card.Body>
                    </Card>

                    <Card>
                      <Card.Header
                        title={`${t('ProductGridView.Cards.Anchors.title')}`}
                      />
                      <Card.Body>
                        <Box display="grid" gap="6">
                          <Checkbox
                            name="enabled_custom_elements"
                            disabled={loading}
                            checked={formData.enabled_custom_elements}
                            onChange={handleChangeForm}
                            label={`${t(
                              'ProductGridView.Form.Fields.enabled_custom_elements.label',
                            )}`}
                          />
                          {formData.enabled_custom_elements ? (
                            <>
                              <Text>
                                {t('ProductGridView.Cards.Anchors.description')}
                              </Text>
                              <FormField
                                label={`${t(
                                  'ProductGridView.Form.Fields.anchor_element_add_to_cart.label',
                                )}`}
                              >
                                <Input
                                  disabled={loading}
                                  id="anchor_element_add_to_cart"
                                  name="anchor_element_add_to_cart"
                                  onChange={handleChangeForm}
                                  value={formData.anchor_element_add_to_cart}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ProductGridView.Form.Fields.anchor_element_qty_box.label',
                                )}`}
                              >
                                <Input
                                  disabled={loading}
                                  id="anchor_element_qty_box"
                                  name="anchor_element_qty_box"
                                  onChange={handleChangeForm}
                                  value={formData.anchor_element_qty_box}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ProductGridView.Form.Fields.anchor_element_variants_group.label',
                                )}`}
                              >
                                <Input
                                  disabled={loading}
                                  id="anchor_element_variants_group"
                                  name="anchor_element_variants_group"
                                  onChange={handleChangeForm}
                                  value={formData.anchor_element_variants_group}
                                />
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ProductGridView.Form.Fields.anchor_element_variants_option.label',
                                )}`}
                              >
                                <Input
                                  disabled={loading}
                                  id="anchor_element_variants_option"
                                  name="anchor_element_variants_option"
                                  onChange={handleChangeForm}
                                  value={
                                    formData.anchor_element_variants_option
                                  }
                                />
                              </FormField>

                              <Title as="h5">
                                {t(
                                  'ProductGridView.Form.Fields.General.Title.product_page_position',
                                )}
                              </Title>
                              <FormField
                                label={`${t(
                                  'ProductGridView.Form.Fields.product_page_mobile_position_method.label',
                                )}`}
                              >
                                <Box
                                  display="flex"
                                  flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                                  gap="4"
                                  alignItems="center"
                                >
                                  <Box width={{ xs: '100%', md: '100px' }}>
                                    <Select
                                      disabled={loading}
                                      id="product_page_mobile_position_method"
                                      name="product_page_mobile_position_method"
                                      onChange={handleChangeForm}
                                      value={
                                        formData.product_page_mobile_position_method
                                      }
                                    >
                                      <PositionMethodOptions />
                                    </Select>
                                  </Box>
                                  <Text as="span">
                                    {t(
                                      'ProductGridView.Form.Fields.General.divisor',
                                    )}
                                  </Text>
                                  <Box width={{ xs: '100%', md: '250px' }}>
                                    <Input
                                      disabled={loading}
                                      id="product_page_mobile_position"
                                      name="product_page_mobile_position"
                                      onChange={handleChangeForm}
                                      value={
                                        formData.product_page_mobile_position
                                      }
                                      placeholder="#element-id"
                                    />
                                  </Box>
                                </Box>
                              </FormField>
                              <FormField
                                label={`${t(
                                  'ProductGridView.Form.Fields.product_page_position_method.label',
                                )}`}
                              >
                                <Box
                                  display="flex"
                                  flexWrap={{ xs: 'wrap', md: 'nowrap' }}
                                  gap="4"
                                  alignItems="center"
                                >
                                  <Box width={{ xs: '100%', md: '100px' }}>
                                    <Select
                                      disabled={loading}
                                      id="product_page_position_method"
                                      name="product_page_position_method"
                                      onChange={handleChangeForm}
                                      value={
                                        formData.product_page_position_method
                                      }
                                    >
                                      <PositionMethodOptions />
                                    </Select>
                                  </Box>
                                  <Text as="span">
                                    {t(
                                      'ProductGridView.Form.Fields.General.divisor',
                                    )}
                                  </Text>
                                  <Box width={{ xs: '100%', md: '250px' }}>
                                    <Input
                                      disabled={loading}
                                      id="product_page_position"
                                      name="product_page_position"
                                      onChange={handleChangeForm}
                                      value={formData.product_page_position}
                                      placeholder="#element-id"
                                    />
                                  </Box>
                                </Box>
                              </FormField>
                            </>
                          ) : (
                            <></>
                          )}
                        </Box>
                      </Card.Body>
                    </Card>

                    <Box display="flex" justifyContent="flex-end">
                      <Button
                        disabled={loading}
                        appearance="primary"
                        type="submit"
                        onClick={handleSubmit}
                      >
                        {loading ? (
                          <Spinner color="currentColor" size="small" />
                        ) : (
                          ''
                        )}
                        {t('general.save')}
                      </Button>
                    </Box>
                  </>
                )}
              </Box>
            </Layout.Section>
            <Layout.Section>
              <ThemeImgPreview />
            </Layout.Section>
          </Layout>
        </Page.Body>
      </Page>
    </>
  );
};

export default ProductGridViewPage;
